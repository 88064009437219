import Vue from "vue";
import VueRouter from "vue-router";
import store from "../store/index";
import AppAside from "@/layout/AppAside.vue";
Vue.use(VueRouter);
Vue.component("AppAside", AppAside);
import notfound from "@/views/not_found.vue";

const login = () => import("@/views/Login.vue");
//---Start Company
const settings = () => import("@/views/settings/settings.vue");
const settingsNav = () => import("@/views/settings/settingsNav.vue");
const settingsGeneral = () => import("@/views/settings/general.vue");
const notifications = () => import("@/views/settings/notifications.vue");
const invoices = () => import("@/views/settings/invoices.vue");
const payments_settings = () => import("@/views/settings/payments.vue");
const advanced_settings = () => import("@/views/settings/advanced.vue");
const messages = () => import("@/views/messages.vue");

const employees_page = () => import("@/views/employees/employees_page");
const employee = () => import("@/views/employees/employee");
const groups = () => import("@/views/groups");
const sections = () => import("@/views/sections");
const brands = () => import("@/views/brands");

const products = () => import("@/views/products/products");
const product = () => import("@/views/products/product");
const banners = () => import("@/views/banners");
const blocks = () => import("@/views/blocks/blocks");
const block = () => import("@/views/blocks/block");
const intro_pages = () => import("@/views/Intro_pages");
const params = () => import("@/views/params");

const delivery = () => import("@/views/delivery/delivery");
const campaigns = () => import("@/views/campaigns/campaigns");
const campaign = () => import("@/views/campaigns/campaign");

const dashboard = () => import("@/views/dashboard");

//--------- begin Ordedrs
const locations = () => import("@/views/orders/locations");
const orders = () => import("@/views/orders/orders");
const ordersReport = () => import("@/views/orders/ordersReport");
const coupons = () => import("@/views/coupons");

//--------- End  Ordedrs

// ---------- customers
const customers = () => import("@/views/customers/customers");
const customer_orders = () => import("@/views/customers/customer_orders");

//----------- End customers

//---Start Super_admin
const companies = () => import("@/views/super_admin/companies");
//---End Super admin

const logs = () => import("@/views/logs");
const problem = () => import("@/views/problem");
const routes = [
  {
    path: "/companies",
    name: "companies",
    component: companies,
    meta: {
      title: "companies",
      requiresAuth: true,
      super_admin: true,
    },
  },
  //////////////------------- End Super_admin
  {
    path: "/dashboard",
    name: "dashboard",
    component: dashboard,
    meta: {
      title: "dashboard",
      requiresAuth: true,
      super_admin: false,
      permission: "dashboard access",
    },
  },
  {
    path: "/settings",

    component: settings,
    meta: {
      title: "settings",
      requiresAuth: true,
      super_admin: false,
      permission: "settings access",
    },
    children: [
      {
        path: "/",
        name: "settingsNav",
        component: settingsNav,
        meta: {
          title: "settingsNav",
        },
      },
      {
        path: "general",
        name: "settingsGeneral",
        component: settingsGeneral,
        meta: {
          title: "general",
        },
      },
      {
        path: "notifications",
        name: "notifications",
        component: notifications,
        meta: {
          title: "notifications",
        },
      },
      {
        path: "invoices",
        name: "invoices",
        component: invoices,
        meta: {
          title: "invoices",
        },
      },
      {
        path: "payments",
        name: "payments",
        component: payments_settings,
        meta: {
          title: "payments",
        },
      },
      {
        path: "advanced",
        name: "advanced",
        component: advanced_settings,
        meta: {
          title: "advanced",
        },
      },
    ],
  },

  {
    path: "/messages",
    name: "messages",
    component: messages,
    meta: {
      title: "messages",
      requiresAuth: true,
      super_admin: false,
      permission: "notify_topic",
    },
  },

  //--------------- Employees
  {
    path: "/employees",
    name: "employees",
    component: employees_page,
    meta: {
      title: "employees",
      requiresAuth: true,
      super_admin: false,
      permission: "employees access",
    },
  },

  {
    path: "/employee",
    name: "employee",
    component: employee,
    meta: {
      title: "employee",
      requiresAuth: true,
      super_admin: false,
      permission: "employees access",
    },
  },
  {
    path: "/groups",
    name: "groups",
    component: groups,
    meta: {
      title: "groups",
      requiresAuth: true,
      super_admin: false,
      permission: "employees access",
    },
  },

  //--------------- Sections
  {
    path: "/sections",
    name: "sections",
    component: sections,
    meta: {
      title: "sections",
      requiresAuth: true,
      super_admin: false,
      permission: "sections access",
    },
  },
  {
    path: "/brands",
    name: "brands",
    component: brands,
    meta: {
      title: "brands",
      requiresAuth: true,
      super_admin: false,
      permission: "brands access",
    },
  },
  //--------------- Products
  {
    path: "/products",
    name: "products",
    component: products,
    meta: {
      title: "products",
      requiresAuth: true,
      super_admin: false,
      permission: "products access",
    },
  },
  {
    path: "/banners",
    name: "banners",
    component: banners,
    meta: {
      title: "banners",
      requiresAuth: true,
      super_admin: false,
      permission: "banners access",
    },
  },

  {
    path: "/product/:id?",
    name: "product",
    component: product,
    meta: {
      title: "product",
      requiresAuth: true,
      super_admin: false,
      permission: "products create",
    },
  },
  {
    path: "/delivery",
    name: "delivery",
    component: delivery,
    meta: {
      title: "delivery",
      requiresAuth: true,
      super_admin: false,
      permission: "delivery access",
    },
  },
  {
    path: "/blocks",
    name: "blocks",
    component: blocks,
    meta: {
      title: "blocks",
      requiresAuth: true,
      super_admin: false,
      permission: "blocks access",
    },
  },
  {
    path: "/block/:id?",
    name: "block",
    component: block,
    meta: {
      title: "block",
      requiresAuth: true,
      super_admin: false,
      permission: "blocks access",
    },
  },
  {
    path: "/campaigns",
    name: "campaigns",
    component: campaigns,
    meta: {
      title: "campaigns",
      requiresAuth: true,
      super_admin: false,
      permission: "campaigns access",
    },
  },
  {
    path: "/campaign/:id?",
    name: "campaign",
    component: campaign,
    meta: {
      title: "campaign",
      requiresAuth: true,
      super_admin: false,
      permission: "campaigns access",
    },
  },
  {
    path: "/orders",
    name: "orders",
    component: orders,
    meta: {
      title: "orders",
      requiresAuth: true,
      super_admin: false,
      permission: "orders access",
    },
  },
  {
    path: "/ordersReport",
    name: "ordersReport",
    component: ordersReport,
    meta: {
      title: "ordersReport",
      requiresAuth: true,
      super_admin: false,
      permission: "orders access",
    },
  },

  {
    path: "/coupons",
    name: "coupons",
    component: coupons,
    meta: {
      title: "coupons",
      requiresAuth: true,
      super_admin: false,
      permission: "coupons access",
    },
  },
  //--------------- Start Orders
  {
    path: "/locations",
    name: "locations",
    component: locations,
    meta: {
      title: "locations",
      requiresAuth: true,
      super_admin: false,
      permission: "locations access",
    },
  },
  {
    path: "/intro_pages",
    name: "intro_pages",
    component: intro_pages,
    meta: {
      title: "intro_pages",
      requiresAuth: true,
      super_admin: false,
      permission: "intro_pages access",
    },
  },
  {
    path: "/params",
    name: "params",
    component: params,
    meta: {
      title: "params",
      requiresAuth: true,
      super_admin: false,
      permission: "params access",
    },
  },
  {
    path: "/customers",
    name: "customers",
    component: customers,
    meta: {
      title: "customers",
      requiresAuth: true,
      super_admin: false,
      permission: "customers access",
    },
  },
  {
    path: "/customer_orders/:id",
    name: "customer_orders",
    component: customer_orders,
    meta: {
      title: "customer_orders",
      requiresAuth: true,
      super_admin: false,
      permission: "customer_orders access",
    },
  },

  //--------------- End Orders

  {
    path: "/logs",
    name: "logs",
    component: logs,
    meta: {
      title: "logs",
      requiresAuth: true,
      super_admin: false,
    },
  },

  //--------------- public
  {
    path: "/",
    name: "login",
    component: login,
    meta: {
      title: "login",
      requiresAuth: false,
    },
  },

  {
    path: "*",
    name: "problem",
    component: notfound,
    meta: {
      title: "problem",
      requiresAuth: false,
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;

router.beforeEach(async (to, from, next) => {
  document.title = to.meta.title;
  let permissions = store.state.userPermissions.data;
  const isCompany = store.getters.isCompany;

  if (
    store.getters.loggedin &&
    !store.state.userPermissions.done &&
    isCompany
  ) {
    await store.dispatch("requirements");
    permissions = store.state.userPermissions.data;
  } 


  if (to.name != "problem") {
    if (!to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next();
      } else {
        if (!isCompany) {
          next("/companies");
        } else {
          if (permissions.includes("products")) {
            next("/products");
          } else if (permissions.length != 0) {
            next({ name: store.getters.first_route });
          } else {
            next("/problem");
          }
        }
      }
    } else if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (!store.getters.loggedin) {
        next({
          name: "login",
        });
      } else {
        if (!isCompany) {
          if (to.name == "login") {
            next({ name: "companies" });
          } else {
            next();
          }
        } else {
          if (to.name == "companies") {
            next("*");
          } else {
            if (
              to.matched.some((record) =>
                permissions.includes(record.meta.permission)
              )
            ) {
              next();
            } else {
              next("*");
            }
          }
        }
      }
    }
  } else {
    next();
  }
});
