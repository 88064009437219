<template>
  <v-navigation-drawer
    class="menu rounded"
    floating
    v-model="$store.state.drawer"
    right
    :mini-variant.sync="$store.state.mini"
    :app="$store.state.break_point <= 991"
    :style="{
      height:
        $store.state.break_point <= 991 ? '100%!important' : 'auto!important',
    }"
  >
    <v-list-item class="px-2">
      <v-list-item-avatar>
        <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
      </v-list-item-avatar>

      <v-list-item-title
        >{{ $store.state.superline_user.name }}
      </v-list-item-title>

      <v-btn
        color="grey lighten-3"
        fab
        x-small
        depressed
        v-if="$store.state.break_point > 991"
        @click.stop="$store.state.mini = !$store.state.mini"
      >
        <v-icon>chevron_right</v-icon>
      </v-btn>
    </v-list-item>
    <v-divider></v-divider>
    <v-list dense v-for="(list, i) in links" :key="i">
      <v-list-item-title
        v-if="!$store.state.mini"
        class="grey--text lighten-3 caption px-4"
        >{{ list.title }}</v-list-item-title
      >
      <v-list-item
        active-class="primary white--text"
        v-for="item in list.list"
        :key="item.title"
        link
        :to="{ name: item.link }"
      >
        <v-list-item-icon class="me-2">
          <v-icon style="font-size: 18px">{{ item.icon }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">{{
            item.title
          }}</v-list-item-title>
        </v-list-item-content>
        <v-list-item-action
          v-if="item.link == 'orders' && $store.state.count_active_orders != 0"
        >
          <v-badge
            offset-y="8"
            color="pink lighten-4"
            :content="$store.state.count_active_orders"
          ></v-badge>
        </v-list-item-action>
      </v-list-item>

      <v-list-item active-class="primary white--text" @click="logout()">
        <v-list-item-icon class="me-2">
          <v-icon style="font-size: 18px">logout</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title class="font-weight-bold">להתנתק</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider v-if="i != list.list.length - 1"></v-divider>
    </v-list>

    <!-- <template slot="append">
      <v-divider></v-divider>
      <v-list-item class="px-2 align-center d-flex">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>

        <v-list-item-title>
          <h5>John Leider</h5>
          <p class="caption mb-0">lore ipiasdsa</p>
        </v-list-item-title>
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="grey lighten-3"
              fab
              depressed
              x-small
              dark
              v-bind="attrs"
              v-on="on"
            >
              <v-icon color="black">more_vert</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item v-for="item in 3" :key="item">
              <v-list-item-title>{{ item }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-list-item>
    </template> -->
  </v-navigation-drawer>
</template>
<script>
export default {
  data() {
    return {
      drawer: true,
      count_active_orders: 0,
      all_links: [
        {
          title: "",
          super: false,
          list: [
            {
              title: "סיכום",
              icon: "dashboard",
              permission: "dashboard access",
              link: "dashboard",
            },
            {
              title: "דוח חכם",
              icon: "query_stats",
              permission: "orders access",
              link: "ordersReport",
            },
            {
              title: "הזמנות",
              icon: "local_mall",
              link: "orders",
              permission: "orders access",
            },

            {
              title: "מוצרים",
              icon: "inventory_2",
              permission: "products access",
              link: "products",
            },
            {
              title: "קטגוריות",
              icon: "category",
              link: "sections",
              permission: "sections access",
            },
            {
              title: "מותגים",
              icon: "approval",
              link: "brands",
              permission: "brands access",
            },
            {
              title: "שליחים",
              icon: "rv_hookup",
              link: "delivery",
              permission: "delivery access",
            },
            {
              title: "מבצעים",
              icon: "redeem",
              link: "campaigns",
              permission: "campaigns access",
            },
            {
              title: "קופונים",
              icon: "local_mall",
              link: "coupons",
              permission: "coupons access",
            },

            {
              title: "איזורים",
              icon: "map",
              link: "locations",
              permission: "locations access",
            },
            {
              title: "פרסומות",
              icon: "image",
              link: "banners",
              permission: "banners access",
            },
            {
              title: "סטריפים",
              icon: "grid_view",
              link: "blocks",
              permission: "blocks access",
            },
            {
              title: "דפי תוכן",
              icon: "article",
              link: "intro_pages",
              permission: "intro_pages access",
            },
            {
              title: "פרמטרים",
              icon: "checklist",
              link: "params",
              permission: "params access",
            },
            {
              title: "לקוחות",
              icon: "group",
              link: "customers",
              permission: "customers access",
            },
            {
              title: "קבוצות",
              icon: "diversity_3",
              link: "groups",
              permission: "employees access",
            },
            {
              title: "עובדים",
              icon: "manage_accounts",
              link: "employees",
              permission: "employees access",
            },
            {
              title: "התראות",
              icon: "manage_accounts",
              link: "messages",
              permission: "notify_topic",
            },
            {
              title: "הגדרות",
              icon: "settings",
              link: "settingsNav",
              permission: "settings access",
            },
          ],
        },
        {
          title: "",
          super: true,
          list: [
            {
              title: "עסקים",
              icon: "corporate_fare",
              link: "companies",
            },
          ],
        },
      ],

      mini: false,
    };
  },
  computed: {
    links() {
      let links = [];
      this.all_links.forEach((e) => {
        if (e.super == !this.$store.getters.isCompany) {
          if (!e.super) {
            const list = [];
            e.list.forEach((item) => {
              if (
                this.$store.state.userPermissions.data.includes(item.permission)
              ) {
                list.push(item);
              }
            });
            links.push({
              list: list,
            });
          } else {
            links.push(e);
          }
        }
      });
      return links;
    },
  },
  methods: {
    logout() {
      let vm = this;
      this.$store.commit("logout");
      setTimeout(() => {
        vm.$router.push({ name: "login" });
      });
    },
  },
  mounted() {},
};
</script>

<style lang="scss">
.menu {
  // min-width: 245px;
  height: calc(100vh - 150px) !important;
  min-height: calc(100vh - 150px) !important;
  @media (max-width: 991px) {
    height: 100vh !important;
    min-height: 100vh !important;
  }
  max-height: calc(100vh - 200px) !important;
  box-shadow: 0px 3px 1px -5px rgb(0 0 0 / 12%), 0px 2px 4px 0px rgb(0 0 0 / 0%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  /* Customize the scrollbar for WebKit browsers (Chrome, Safari) */
  ::-webkit-scrollbar {
    width: 6px; /* Width of the scrollbar */
  }

  /* Track (the background of the scrollbar) */
  ::-webkit-scrollbar-track {
    background: #f1f1f1; /* Color of the scrollbar track */
    border-radius: 10px; /* Rounded corners for the track */
  }

  /* Handle (the draggable part of the scrollbar) */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Color of the scrollbar handle */
    border-radius: 10px; /* Rounded corners for the handle */
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Color of the scrollbar handle when hovered */
  }
}
</style>
