
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage, subscribeTopic} from "firebase/messaging";

// Configure Firebase.
const firebaseConfig = {
  apiKey: "AIzaSyBxMXU_fIfqf1DfwZU0k47_i5UkO0vhees",
  authDomain: "superline-efa26.firebaseapp.com",
  projectId: "superline-efa26",
  storageBucket: "superline-efa26.firebasestorage.app",
  messagingSenderId: "725136399735",
  appId: "1:725136399735:web:4595bd76ffa04868ced572",
  measurementId: "G-NDVQ0RK62R"
};

const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export { messaging, getToken, onMessage, getMessaging,subscribeTopic };


