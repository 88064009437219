<template>
  <div class="header mb-5">
    <v-app-bar
      :app="$store.state.break_point <= 991"
      color="white"
      flat
      height="65"
    >
      <v-container class="py-0 align-center d-flex justify-space-between">
        <h1
          v-if="
            !$store.getters.loggedin || !$store.state.superline_user.is_company
          "
        >
          superline
        </h1>
        <div v-else class="d-flex">
          <img
            width="100"
            height="40"
            :src="`${$store.state.def_path}/${$store.state.superline_user.logo}`"
            alt=""
          />
          <v-btn icon @click="toOrders()">
            <v-badge
              color="red"
              overlap
              :content="$store.getters.notifications"
              :value="$store.getters.notifications"
            >
              <v-icon>notifications</v-icon>
            </v-badge>
          </v-btn>
        </div>
        <v-btn
          v-if="$store.state.break_point <= 991 && $store.getters.loggedin"
          small
          icon
          @click="$store.state.drawer = !$store.state.drawer"
        >
          <v-icon>menu</v-icon>
        </v-btn>
      </v-container>
    </v-app-bar>
  </div>
</template>

<script>
export default {
  name: "AppHeader",
  data: () => ({}),
  methods: {
    toOrders() {
      if (this.$store.getters.notifications && this.$route.name != "orders") {
        this.$router.push("/orders");
      }
      this.$store.commit("resetNotifications");
    },
  },
};
</script>
