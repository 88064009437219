import { getToken, messaging, onMessage, getMessaging } from "@/firebase";
import notificationSoundFile from "@/assets/notification.wav";

export default {
  name: "FirebaseMixin",
  data() {
    return {};
  },

  mounted() {
    const getters = this.$store.getters;

    if (getters.loggedin && getters.isCompany) {
      this.requestNotificationPermission();
      const channel = new window.BroadcastChannel("sw-messages");
      channel.addEventListener("message", (event) => {
        this.pushSound();
        this.$store.commit("increaseNotification");
      });
      onMessage(messaging, (payload) => {
        this.$store.commit("increaseNotification");
        const notificationSound = new Audio(notificationSoundFile);
        notificationSound.play().catch((err) => {
          this.pushSound();
        });
      });
    }
  },
  watch: {
    "$store.getters.loggedin"(val) {
      if (val) {
        this.requestNotificationPermission();
      }
    },
  },
  methods: {
    pushSound() {
      let iframe = document.createElement("iframe");
      iframe.style.display = "none";
      iframe.src = notificationSoundFile;
      iframe.allow = "autoplay";
      iframe.id = "audio";

      document.body.appendChild(iframe);
    },
    async requestNotificationPermission() {
      try {
        const permission = await Notification.requestPermission();
        if (permission === "granted") {
          this.getToken();
        }
      } catch (error) {
        console.error("Unable to get permission to notify.", error);
      }
    },
    async getToken() {
      try {
        const currentToken = await getToken(messaging, {
          vapidKey:
            "BJR0MXZ_ino1m9MPL_hvv3Ddq2logbN6iDRuATXPjhowdte9mjbyZ3LzWl-pgaUE8_qqmJPAyXWTgeGLOgBM3Bs",
        });
        if (currentToken) {
          const savedToken = this.$store.getters.fcmToken;
          if (!savedToken || savedToken != currentToken) {
            this.$store.commit("saveFcmToken", currentToken);
            this.subscribeTopic(currentToken);
          }
        } else {
          console.log("No registration token available.");
        }
      } catch (err) {
        console.error("Error subscribing to topic:", err);
      }
    },
    subscribeTopic(token) {
      this.$store.dispatch("public__request", {
        config: {
          url: `subscribeToTopic/${token}`,
          method: "post",
        },
      });
    },
  },
};
