import Vue from "vue";

import App from "./App.vue";
import axios from "axios";
import VueAxios from "vue-axios";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Vuetify from "vuetify";

Vue.use(Vuetify);
Vue.use(VueAxios, axios);

axios.defaults.baseURL = "https://ap.superline.app/api/v2/";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api/v2/";
Vue.config.productionTip = false;

if ("serviceWorker" in navigator) {
  navigator.serviceWorker
    .register("/firebase-messaging-sw.js")
    .then((registration) => {
      console.log("Service Worker registered with scope:", registration.scope);
    })
    .catch((err) => {
      console.error("Service Worker registration failed:", err);
    });
}

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
